<template>
  <v-card :flat="flat" :loading="loading" :disabled="loading" class="base-form">
    <v-toolbar flat>
      <v-app-bar-nav-icon v-if="!dialog"
        ><v-btn title="voltar" icon @click="$router.go(-1)"
          ><v-icon>mdi-keyboard-backspace</v-icon></v-btn
        ></v-app-bar-nav-icon
      >
      <v-toolbar-title v-if="title"> {{ title }} </v-toolbar-title>

      <v-spacer></v-spacer>

      <slot name="header-actions"></slot>
    </v-toolbar>

    <v-card-text>
      <slot name="content"></slot>
    </v-card-text>
    <v-divider v-if="divider"></v-divider>
    <v-card-actions>
      <slot name="actions">
        <v-spacer></v-spacer>
        <v-btn text v-if="dialog" @click.stop="$emit('close-dialog')"
          >Cancelar</v-btn
        >
        <v-btn
          v-if="!dialog"
          :loading="loading"
          class="indigo white--text"
          @click="$emit('save', 'back')"
        >
          <v-icon left>mdi-keyboard-backspace</v-icon> Salvar e voltar</v-btn
        >
        <v-btn
          :loading="loading"
          class="success darken-1"
          @click="$emit('save', 'stay')"
        >
          <v-icon left>mdi-content-save</v-icon> Salvar</v-btn
        >
      </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.base-form /deep/ .required label::after {
  content: "*";
  color: #e22d3f;
}
</style>
