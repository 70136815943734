<template>
  <BaseForm
    :title="title"
    :mod="mod"
    :flat="flat"
    :divider="divider"
    :dialog="dialog"
    :loading="loading"
    @save="save"
  >
    <template #content>
      <v-container fluid>
        <v-row>
          <v-col>
            <h3>Texto de assinatura</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ckeditor
              v-model="form.signatureText"
              :config="editorConfig"
            ></ckeditor>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>Imagem</h3>
          </v-col>
        </v-row>
        <v-row v-if="signatureImage">
          <v-col>
            <v-img max-width="300" :src="imageViewSrc"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              placeholder="Alterar imagem"
              v-model="form.signatureImage"
              accept="image/*"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions></template>
  </BaseForm>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import errorHandler from "@/helpers/error_handler";
import CKEditor from "ckeditor4-vue";
import BaseForm from "@/components/template/BaseForm";
import { baseUrl } from "@/config/config.json";

export default {
  components: {
    BaseForm,
    ckeditor: CKEditor.component,
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      form: {
        signatureText: "<p>Dr. Fulano de tal</p><p>Crm: xxxx RQE: xxxx</p>",
      },
      doctorId: null,
      signatureImage: null,
      editorConfig: {
        extraAllowedContent: "div(*)[*]",
        disableNativeSpellChecker: false,
        removeButtons:
          "ImageButton,HiddenField,Flash,Smiley,Iframe,About,Source,Anchor",
      },
    };
  },
  methods: {
    save(actionAfterOperation) {
      const errors = this.validateForm();

      if (errors.length) {
        this.$root.$errorDialog(errors, {
          width: "800",
          color: "primary",
        });
      } else {
        this[this.mod](actionAfterOperation);
      }
    },
    insert() {},
    async update(actionAfterUpdate) {
      try {
        this.loading = true;

        const dataToSend = this.prepareDataTosend(this.form);

        await this.$axios.put(`/doctors/signature`, dataToSend);
        this.loading = false;

        this.afterUpdate(actionAfterUpdate);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async loadSignatureData() {
      try {
        this.loading = true;

        const response = await this.$axios.get(`/doctors/signature`);

        this.loading = false;

        this.setSignatureData(response.data);
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    prepareDataTosend(data) {
      const formData = new FormData();

      formData.append("signatureText", data.signatureText);

      if (data.signatureImage)
        formData.append("signatureImage", data.signatureImage);

      return formData;
    },
    setSignatureData(data) {
      const copyOfData = { ...data };

      this.signatureImage = copyOfData.signatureImage;

      this.doctorId = copyOfData.id;

      this.form = { signatureText: copyOfData.signatureText };
    },
    validateForm() {
      const errors = [];

      if (!this.form.signatureText)
        errors.push("Informe o texto complementar da assinatura");

      if (this.mod === "insert" && !this.form.signatureImage)
        errors.push("Informe uma imagem para a assinatura");

      return errors;
    },
  },
  computed: {
    imageViewSrc() {
      return `${baseUrl}/doctors/${this.doctorId}/signature/image`;
    },
  },
  created() {
    if (this.mod === "update") this.loadSignatureData();
  },
};
</script>

<style>
.label-mask {
  font-size: 1.05rem;
}
</style>
