export default {
  props: {
    databaseId: {
      type: Number,
      default: null,
    },
    mod: {
      type: String,
    },
    dataFromStore: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    divider: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clearForm(dataToInitializeTheForm = {}) {
      this.form = dataToInitializeTheForm;
    },
    afterUpdate(actionAfterUpdate) {
      if (this.$props.dialog) this.$emit("update-completed");

      if (actionAfterUpdate === "back") this.$router.go(-1);
    },
    afterInsertion(actionAfterInsertion, recordInserted, dataToInitializeTheForm) {
      if (this.$props.dialog) {
        this.clearForm(dataToInitializeTheForm);
        this.$emit("insert-completed", recordInserted);
      } else {
        if (actionAfterInsertion === "back") this.$router.go(-1);
        else if (actionAfterInsertion === "stay") this.clearForm(dataToInitializeTheForm);
      }
    },
  },
};
